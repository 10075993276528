import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as templateHelper from './templateHelper';

class ManagedToggle extends Component {
  render() {
    const show = this.props.value === true;
    if (show) {
      return (
        <div className={this.props.className || ''}>{this.props.children}</div>
      );
    }

    return null;
  }
}

const mapState = (state, ownProps) => {
  const customValue = ownProps.customValue;
  if (customValue !== undefined) {
    return {
      value: customValue
    };
  }

  let value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  if (ownProps.value) {
    value = value === ownProps.value;
  } else if (ownProps.values) {
    value = ownProps.values.includes(value);
  }
  
  return {
    value
  };
};

export const TemplateToogle = connect(mapState)(ManagedToggle);
