//-------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

//-------Helpers
import * as templateHelper from '../../template/templateHelper';
import * as validationHelper from '../../../helpers/validationHelper';
import update from '../../../helpers/update';

//-------Components
import { AmountItem } from './amountItem';
import { Form, Button, Label } from 'semantic-ui-react';
import TextInput from '../../form/input';
import { TemplateText } from '../../../components/template/templateText';

//-------Other
import './index.css';

class AmountListInputContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ''
    };

    this.validationModel = {
      amountListBuilder: true
    };
  }

  onChange = (value) => {
    this.props.onChange(this.props.path, value);
  };

  onInputChange = (event) => {
    this.setState({
        inputValue: event.target.value
    });
  };

  getItems = () => {
    const items = this.props.value;

    if (!items) {
      return null;
    }

    const renderItems = items.map((item, index) => {
      return (
        <AmountItem index={index} label={item} currency={this.props.currency} onDelete={this.onDeleteClick} />
      );
    });

    return renderItems;
  };

  onAddItemClick = () => {
    let valueCopy = this.props.value || [];

    const valueEntered = parseInt(this.state.inputValue);
    if (!valueEntered || isNaN(valueEntered) || valueEntered <= 0) {
      return;
    }

    valueCopy = update(valueCopy, {
      $push: [valueEntered]
    });

    this.setState({
        inputValue: ''
    });

    this.onChange(valueCopy);
  };

  onDeleteClick = (index) => {
    let valueCopy = this.props.value;

    valueCopy = update(valueCopy, {
      $splice: [[index, 1]]
    });

    this.onChange(valueCopy);
  };

  isAddButtonDisabled = () => {
    const isValid = validationHelper.isValid(this.validationModel, {
      amountList: this.props.value,
      inputValue: this.state.inputValue
    });    
    return !(this.state.inputValue !== '' && isValid && this.props.value.length < this.props.maxItems);
  };

  render() {
    const amountList = this.getItems();
    const validationErrorMessage = validationHelper.getValidationErrorMessage(
      this.validationModel,
      { amountList: this.props.value,
        inputValue: this.state.inputValue }
    );

    return (
    <div className='amount-global-container'>
      <TemplateText
        text={I18n.t(this.props.label)}
      />
      <div className="amount-container">
        <Form>
          <Form.Group unstackable>
            <Form.Field width={8}>
              <TextInput
                id="amount-input"
                value={this.state.inputValue}
                placeholder={I18n.t(this.props.inputLabel)}
                onChange={this.onInputChange}
                validationErrorMessage={validationErrorMessage}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Button
                id="add-amount-btn"
                disabled={this.isAddButtonDisabled()}
                onClick={this.onAddItemClick}
              >
                {I18n.t(this.props.addButtonText)}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="amount-list-container">{amountList}</div>
      </div>
    </div>
    );
  }
}

const mapState = (state, ownProps) => {
  let value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);
  // define a new variable so if value is not an array but a string or number, convert it into an array
  if (!Array.isArray(value)) {
    value = value ? [value] : [];
  }
  return {
    value,
    currency: ownProps.currency,
    maxItems: ownProps.maxItems
  };
};

export const AmountListInput = connect(mapState)(AmountListInputContainer);
